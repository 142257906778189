import React from 'react'

export const LoaderCards: React.FC = () => (
  <svg width="100%" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2103 1121">
    <path
      opacity=".2"
      d="m320.965 1051.6-296-181.504c-23.9-14.6-31.3-45.8-16.7-69.6l318.3-519.2c14.6-23.8 45.8-31.3 69.6-16.7l296 181.5c23.8 14.6 31.3 45.8 16.7 69.6l-318.3 519.204c-14.6 23.8-45.8 31.3-69.6 16.7Z"
      fill="#000"
    />
    <path
      d="m357.665 1046.7-296-181.504c-23.8-14.6-31.3-45.8-16.7-69.6l318.3-519.2c14.6-23.8 45.8-31.3 69.6-16.7l296 181.5c23.8 14.6 31.3 45.8 16.7 69.6L427.265 1030c-14.5 23.9-45.7 31.3-69.6 16.7Z"
      fill="#F5CF0E"
    />
    <path d="m492.865 874.096-432.4-54.7 237.3-387 432.4 54.7-237.3 387Z" fill="url(#a)" />
    <path
      d="m598.765 427.896-124.7 87.3-28-17.2 120.8-80.7-66.5-40.8 13.1-21.4 94.5 57.9-9.2 14.9Zm-402.6 444.7 124.7-87.3 28 17.2-120.8 80.6 66.5 40.8-13.1 21.4-94.5-57.9 9.2-14.8Z"
      fill="#fff"
    />
    <path
      opacity=".2"
      d="m740.572 1116.97-325-122.197c-26.2-9.8-39.4-39-29.6-65.2l214.4-570c9.8-26.2 39-39.4 65.2-29.6l325 122.2c26.198 9.8 39.398 39 29.598 65.2L805.772 1087.37c-9.8 26.2-39 39.4-65.2 29.6Z"
      fill="#000"
    />
    <path
      d="m766.272 1106.07-325-122.197c-26.2-9.8-39.4-39-29.6-65.2l214.4-570c9.8-26.2 39-39.4 65.2-29.6l324.998 122.2c26.2 9.8 39.4 39 29.6 65.2L831.472 1076.47c-9.8 26.2-39 39.5-65.2 29.6Z"
      fill="#29A7DD"
    />
    <path d="m866.372 910.973-434.9 28.1 159.8-424.9 434.898-28.1-159.798 424.9Z" fill="url(#b)" />
    <path
      d="m767.772 470.873 36.2-70.8 81.5 30.7-9.1 24.1-57.9-21.8-15.4 29.9c8.2-1.1 16.2-.2 24 2.7 13.9 5.2 23.1 13.6 27.7 25.1 4.6 11.6 4 25.1-1.8 40.5-3.5 9.4-8.7 17.1-15.4 23-6.8 5.9-14.6 9.6-23.5 10.9-8.9 1.3-18.4.1-28.5-3.7-8.8-3.3-16.3-8.2-22.5-14.6-6.2-6.4-10.3-13.6-12.2-21.7-1.9-8.1-1.5-16.3 1.4-24.7l28.6 10.7c-1.7 6.3-1.3 11.8 1 16.5 2.4 4.7 6.5 8.2 12.3 10.4 6.5 2.5 12.4 2 17.7-1.4 5.3-3.4 9.6-9.4 12.8-17.9 3.1-8.3 3.5-15.4 1-21.3-2.4-5.9-7.3-10.3-14.8-13.1-6.9-2.6-13.1-2.9-18.7-.9l-3.8 1.5-20.6-14.1Zm-74.8 468.7-36.2 70.797-81.5-30.697 9.1-24.1 57.9 21.8 15.4-29.9c-8.2 1.1-16.2.2-24-2.7-13.9-5.2-23.1-13.6-27.7-25.1-4.6-11.6-4-25.1 1.8-40.5 3.5-9.4 8.7-17.1 15.4-23 6.8-5.9 14.6-9.6 23.5-10.9 8.9-1.3 18.4-.1 28.5 3.7 8.8 3.3 16.3 8.2 22.5 14.6 6.2 6.4 10.3 13.6 12.2 21.7 1.9 8.1 1.5 16.3-1.4 24.7l-28.6-10.7c1.7-6.3 1.3-11.8-1-16.5-2.4-4.7-6.5-8.2-12.3-10.4-6.5-2.5-12.4-2-17.7 1.4-5.3 3.4-9.6 9.4-12.8 17.9-3.1 8.3-3.5 15.4-1 21.3 2.4 5.9 7.3 10.3 14.8 13.1 6.9 2.6 13.1 2.9 18.7.9l3.8-1.5 20.6 14.1Z"
      fill="#fff"
    />
    <path
      opacity=".2"
      d="m1145.46 1113.94-339.702-71.9c-27.4-5.8-44.8-32.7-39.1-59.996l126.1-595.8c5.8-27.4 32.7-44.8 60-39.1l339.702 71.9c27.4 5.8 44.8 32.7 39.1 60l-126.1 595.796c-5.8 27.4-32.7 44.9-60 39.1Z"
      fill="#000"
    />
    <path
      d="m1169.66 1099.84-339.702-71.9c-27.4-5.8-44.8-32.696-39.1-59.996l126.1-595.8c5.8-27.4 32.7-44.8 60-39.1l339.702 71.9c27.4 5.8 44.8 32.7 39.1 60l-126.1 595.796c-5.8 27.4-32.6 44.9-60 39.1Z"
      fill="#2FB166"
    />
    <path d="m1239.26 891.944-425.802 93.2 94-444.1 425.702-93.3-93.9 444.2Z" fill="url(#c)" />
    <path
      d="m1162.16 425.844-5.3 24.6-2.9-.6c-13.6-2.7-25.2-1.4-34.9 3.6-9.7 5.1-16.6 13.5-20.9 25.3 9.7-6.4 20.7-8.4 32.9-5.8 13.1 2.8 22.5 9.7 28.3 20.7 5.7 11 7 24.2 3.7 39.5-2.1 9.8-6.1 18.2-12 25.2s-13.2 11.9-21.9 14.7c-8.7 2.8-17.9 3.1-27.7 1-15.9-3.4-27.5-11.6-34.9-24.7-7.4-13.1-9.2-28.8-5.3-47.2l2.3-10.8c3.5-16.3 9.6-30.1 18.4-41.3 8.8-11.2 19.7-19 32.7-23.4 13-4.4 27.1-5 42.4-1.9l5.1 1.1Zm-45.2 69.3c-4.8-1-9.5-.7-13.9 1-4.5 1.7-8.1 4.4-10.8 8.1l-1.9 9.1c-2.1 10-1.8 18.2.9 24.6 2.7 6.4 7.7 10.4 14.8 11.9 6.4 1.4 12.1-.1 17.2-4.3 5-4.2 8.4-10.4 10.1-18.4 1.7-8.2 1.1-15.2-1.8-21.1-3-5.8-7.8-9.4-14.6-10.9ZM983.558 1005.04l5.2-24.596 2.9.6c13.602 2.7 25.202 1.4 34.902-3.6 9.7-5.1 16.6-13.5 20.9-25.3-9.7 6.4-20.7 8.4-32.9 5.8-13.1-2.8-22.502-9.7-28.302-20.7-5.7-11-7-24.2-3.7-39.5 2.1-9.8 6.1-18.2 12-25.2 5.902-7 13.202-11.9 21.902-14.7 8.7-2.8 17.9-3.1 27.7-1 15.9 3.4 27.5 11.6 34.9 24.7 7.4 13.1 9.2 28.8 5.3 47.2l-2.3 10.8c-3.5 16.3-9.6 30.1-18.4 41.3-8.8 11.2-19.7 19-32.7 23.396-13 4.4-27.1 5-42.402 1.9l-5-1.1Zm45.102-69.296c4.8 1 9.5.7 13.9-1 4.5-1.7 8.1-4.4 10.8-8.1l1.9-9.1c2.1-10 1.8-18.2-.9-24.6-2.7-6.4-7.7-10.4-14.8-11.9-6.4-1.4-12.1.1-17.2 4.3-5 4.2-8.4 10.4-10.1 18.4-1.7 8.2-1.1 15.2 1.8 21.1 3 5.8 7.8 9.4 14.6 10.9Z"
      fill="#fff"
    />
    <path
      opacity=".2"
      d="m1605.75 979.544-345.9 30.696c-27.9 2.5-52.4-18.096-54.9-45.996l-53.8-606.6c-2.5-27.9 18.1-52.4 46-54.9l345.9-30.7c27.9-2.5 52.4 18.1 54.9 46l53.8 606.6c2.4 27.8-18.2 52.4-46 54.9Z"
      fill="#000"
    />
    <path
      d="m1627.55 959.943-345.9 30.7c-27.9 2.5-52.4-18.099-54.9-46l-53.8-606.599c-2.5-27.9 18.1-52.4 46-54.9l345.9-30.7c27.9-2.5 52.4 18.1 54.9 46l53.8 606.6c2.5 27.9-18.1 52.499-46 54.899Z"
      fill="#F12F2F"
    />
    <path d="m1633.25 740.744-379.8 213.8-40.1-452.1 379.8-213.8 40.1 452.1Z" fill="url(#d)" />
    <path
      d="m1381.95 774.644 106.1-9.4 1.9 21.1-45.4 57.8c-6.2 8.1-10.7 15.1-13.5 21-2.8 5.9-4 11.4-3.5 16.4.6 6.9 2.8 12.1 6.7 15.8 3.8 3.6 9 5.2 15.4 4.6 6.9-.6 12.2-3.5 15.8-8.6 3.6-5.1 5-11.6 4.3-19.4l30.8-2.7c.8 9.4-.6 18.2-4.5 26.4-3.8 8.2-9.6 14.9-17.5 20-7.8 5.2-17 8.2-27.4 9.1-16 1.4-28.7-1.3-38.2-8.2-9.5-6.9-14.9-17.3-16.1-31.3-.7-7.7.6-15.6 3.9-23.9 3.3-8.3 9.3-18.1 18-29.6l31.9-40.2-66.6 5.9-2.1-24.8Zm83.7-300.1-106.1 9.399-1.9-21.1 45.4-57.8c6.2-8.1 10.7-15.1 13.5-21 2.8-5.9 4-11.4 3.5-16.4-.6-6.9-2.8-12.1-6.7-15.8-3.8-3.6-9-5.2-15.4-4.6-6.9.6-12.2 3.5-15.8 8.6-3.6 5.1-5 11.6-4.3 19.4l-30.8 2.7c-.8-9.4.6-18.2 4.5-26.4 3.8-8.2 9.6-14.9 17.5-20 7.8-5.2 17-8.2 27.4-9.1 16-1.4 28.7 1.3 38.2 8.2 9.5 6.9 14.9 17.3 16.1 31.3.7 7.7-.6 15.6-3.9 23.9-3.3 8.3-9.3 18.1-18 29.6l-31.9 40.2 66.6-5.9 2.1 24.801Z"
      fill="#fff"
    />
    <path
      opacity=".2"
      d="m2004.04 712.368-315.97 143.947c-25.43 11.613-55.49.377-67.1-25.054l-252.5-554.183c-11.61-25.432-.37-55.493 25.06-67.106L1709.5 66.025c25.43-11.614 55.49-.378 67.1 25.054l252.5 554.183c11.7 25.541.46 55.601-25.06 67.106Z"
      fill="#000"
    />
    <path
      d="m2017.66 685.751-315.97 143.948c-25.43 11.613-55.49.377-67.11-25.055l-252.49-554.183c-11.61-25.431-.38-55.492 25.05-67.105l316.07-143.938c25.44-11.613 55.5-.377 67.11 25.054l252.49 554.183c11.52 25.422.28 55.483-25.15 67.096Z"
      fill="#F5CF0E"
    />
    <path d="M1950.16 477.113 1663.07 805.08l-188.22-413.166 287.08-327.868 188.23 413.067Z" fill="url(#e)" />
    <path
      d="m1670.59 265.411-28.06 12.767-49.33-108.333-28.79 25.656-10.46-22.809 49-47.718 3.05-1.414 64.59 141.851Zm69.29 317.112 28.06-12.767 49.33 108.333 28.79-25.656 10.46 22.809-48.99 47.619-3.05 1.414-64.6-141.752Z"
      fill="#fff"
    />
    <defs>
      <linearGradient id="a" x1="226.034" y1="549.401" x2="564.664" y2="757.028" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F59F0E" />
        <stop offset="1" stopColor="#F5CF0E" />
      </linearGradient>
      <linearGradient id="b" x1="542.896" y1="642.678" x2="914.694" y2="782.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#325CFF" />
        <stop offset="1" stopColor="#29A7DD" />
      </linearGradient>
      <linearGradient id="c" x1="879.002" y1="675.334" x2="1267.6" y2="757.559" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2F9066" />
        <stop offset="1" stopColor="#2FB166" />
      </linearGradient>
      <linearGradient id="d" x1="1225.46" y1="639.153" x2="1621.13" y2="604.044" gradientUnits="userSpaceOnUse">
        <stop stopColor="#AD3F3E" />
        <stop offset="1" stopColor="#F12F2F" />
      </linearGradient>
      <linearGradient id="e" x1="1531.69" y1="516.877" x2="1893.16" y2="352.189" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F59F0E" />
        <stop offset="1" stopColor="#F5CF0E" />
      </linearGradient>
    </defs>
  </svg>
)

LoaderCards.displayName = 'LoaderCards'
