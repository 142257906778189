import { CreatedByInfo } from '../CreatedByInfo'

export const Footer = () => (
  <>
    <br />
    <br />
    <br />
    <CreatedByInfo />
  </>
)
