import React from 'react'
import { styled, keyframes } from '@mui/material/styles'

const blink = keyframes({
  from: {
    opacity: 0,
  },
  '50%': {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
})

const scaleUp = keyframes({
  '0%': {
    transform: 'scale(0.9)',
    opacity: 0,
  },
  '100%': {
    transform: 'scale(1)',
    opacity: 0.5,
  },
})

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
})

const letterMotion = keyframes({
  '0%': {
    transform: 'translateY(0px)',
  },
  '50%': {
    transform: 'translateY(20px)',
  },
  '100%': {
    transform: 'translateY(0px)',
  },
})

const GreyLine = styled('path')(() => ({
  fill: '#6c6c6c',
}))

const LightGreyLine = styled('path')(() => ({
  fill: '#e1e1e1',
}))

const LightGreyCircle = styled('circle')(() => ({
  fill: '#e1e1e1',
}))

const WhiteLine = styled('path')(() => ({
  fill: '#fff',
}))

const WhiteRect = styled('rect')(() => ({
  fill: '#fff',
}))

const GreenLine = styled('path')(() => ({
  fill: '#2fac66',
}))

const CheckedMark = styled(GreenLine)(() => ({
  opacity: 0,
  animation: `${fadeIn} forwards 2s 2.5s`,
}))

const CheckedCircle = styled(GreenLine)(() => ({
  opacity: 0,
  animation: `${fadeIn} forwards 2s 2s`,
}))

const Letter = styled('g')(() => ({
  animation: `${letterMotion} 2s`,
}))

const BlinkedFigure = styled('g')<{ duration: number }>(({ duration }) => ({
  animation: `${blink} ${duration}s infinite`,
}))

const ShadowCircle = styled('ellipse')(() => ({
  animation: `${scaleUp} 3.5s`,
  opacity: 0.5,
  fill: '#e1e1e1',
}))

export const EmailSended: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 346.61 344.48">
    <g>
      <path
        d="M281.29 128.32S172.68 214.15 170 214.15 59.19 128.32 59.19 128.32s106.17-64.27 111.05-63.86 111.05 63.86 111.05 63.86z"
        transform="translate(-4.47 -1.63)"
        fill="#2cab61"
      />
      <GreyLine
        d="M279.52 126.56l-10.79 8.51q-12.92 10.17-25.84 20.31-15.82 12.41-31.7 24.76l-7.61 5.9-1.85 1.44-.83.63-.45.35-.22.17-3.56 2.75q-6.42 5-12.88 9.86c-4.78 3.61-9.51 7.36-14.57 10.57-.06 0-.2.07-.24.12a1.83 1.83 0 011.06-.28c.07 0 .89.17.52.06 1 .3.56.27.31.11-.62-.4-1.24-.78-1.85-1.19-2.52-1.74-5-3.58-7.41-5.41q-5.72-4.29-11.36-8.64l-3.25-2.51-1.6-1.24-.86-.67c-.34-.26.45.36-.1-.07l-.53-.41-7.34-5.68q-15.79-12.27-31.51-24.65Q91 150.32 77 139.25l-15.35-12.14-.69-.55-.51 3.92q5.27-3.18 10.54-6.35 12.72-7.65 25.48-15.26 15.6-9.28 31.27-18.45C137 85 146.19 79.68 155.53 74.51c3.24-1.8 6.5-3.58 9.81-5.27 1.11-.56 2.22-1.12 3.35-1.63l.66-.3c.49-.21-.66.25.07 0l.89-.32c.77-.26-.45 0-.08 0-1 0-.53-.08-.09.07l.38.14c.52.19-.71-.32 0 0l1.23.58c2.62 1.27 5.18 2.66 7.74 4.06 8.62 4.71 17.13 9.62 25.63 14.53q15.72 9.09 31.36 18.32 13.94 8.2 27.83 16.46 7.55 4.47 15.06 9l.69.41c2.76 1.66 5.28-2.66 2.52-4.31q-5.35-3.21-10.72-6.39-12.88-7.66-25.78-15.27-15.7-9.29-31.47-18.46c-9.26-5.38-18.54-10.75-27.9-16-3.18-1.77-6.36-3.53-9.58-5.21a35.56 35.56 0 00-5.76-2.71c-1.74-.54-3.39.33-4.94 1-2.63 1.18-5.18 2.55-7.72 3.91-8.71 4.61-17.2 9.53-25.71 14.45q-15.69 9.08-31.27 18.35-14 8.33-28 16.71l-15.12 9.11-.7.43c-1.25.75-1.81 2.89-.51 3.92l10.92 8.65q13.08 10.35 26.19 20.66 15.85 12.48 31.77 24.9c9.35 7.29 18.71 14.57 28.15 21.74 3.09 2.34 6.18 4.68 9.32 7a37.6 37.6 0 004.71 3.21c1.94 1 3.46-.15 5-1.22 2.5-1.71 4.93-3.53 7.35-5.34 8.71-6.49 17.3-13.14 25.88-19.78q15.85-12.27 31.64-24.65 14.26-11.16 28.5-22.38l15.46-12.18.72-.57a2.51 2.51 0 000-3.53 2.56 2.56 0 00-3.49-.04z"
        transform="translate(-4.47 -1.63)"
      />
    </g>
    <BlinkedFigure duration={2}>
      <LightGreyLine d="M69.7 20.55l-5.06 14.46-15.05 2.85-10-11.61 5.06-14.46L59.7 8.94l10 11.61zM263.19 4.06l-4.88 16.27 14.64-4.07-9.76-12.2z" />
    </BlinkedFigure>
    <BlinkedFigure duration={3}>
      <LightGreyLine d="M331.32 2.43l-5.5 19.52" />
      <LightGreyLine
        d="M333.38 3.39l-4.8 17.08-.69 2.45c-.87 3.1 3.95 4.42 4.82 1.33l4.8-17.09.69-2.44c.87-3.1-3.95-4.43-4.82-1.33z"
        transform="translate(-4.47 -1.63)"
      />
      <LightGreyLine d="M317.2 8.38l22.75 6.69" />
      <LightGreyLine
        d="M321 12.42l19.86 5.84 2.89.85c3.09.91 4.41-3.91 1.33-4.82l-19.85-5.84-2.89-.85c-3.09-.91-4.41 3.91-1.33 4.82z"
        transform="translate(-4.47 -1.63)"
      />
      <LightGreyCircle cx="4.47" cy="37" r="4.47" />
    </BlinkedFigure>
    <Letter>
      <WhiteRect x="79.32" y="81.34" width="172.88" height="146.85" rx="6.71" />
      <GreyLine
        d="M250 227.32H93.15c-2.19 0-4.55.3-6.05-1.72-1.17-1.59-.8-4.15-.8-6V109.19c0-6.45-.06-12.89 0-19.34a4.3 4.3 0 014.21-4.38h159.07a4.43 4.43 0 014 2 5.11 5.11 0 01.64 3v132.46a4.29 4.29 0 01-4.21 4.39c-3.22.08-3.23 5.08 0 5a9.38 9.38 0 009.21-9.24V90.34a10.47 10.47 0 00-1.22-5.11 9.39 9.39 0 00-8.24-4.76H89.82a9.35 9.35 0 00-8.52 9.22v130.04c0 2.8-.22 5.59 1.31 8.11a9.47 9.47 0 008.1 4.48H250c3.18 0 3.19-5 0-5z"
        transform="translate(-4.47 -1.63)"
      />
    </Letter>
    <g>
      <WhiteLine d="M59 128.53v141.15h222.1V128.53s-108.81 85.83-111.25 85.83S59 128.53 59 128.53z" transform="translate(-4.47 -1.63)" />
      <GreyLine
        d="M56.48 128.53v123.72c0 5.72-.18 11.48 0 17.2a1.76 1.76 0 010 .23 2.54 2.54 0 002.5 2.5H272c2.89 0 5.78.06 8.67 0h.37a2.53 2.53 0 002.5-2.5V146c0-5.72.21-11.48 0-17.2v-.23c0-2.36-2.64-3-4.26-1.77l-10.79 8.5q-12.94 10.19-25.92 20.34Q226.84 168 211 180.26l-7.79 6-3.35 2.59-3.55 2.74q-6.4 4.94-12.85 9.84c-4.75 3.6-9.48 7.29-14.47 10.55-.06 0-.2.07-.24.12a1.86 1.86 0 011.07-.28c1 0 .31 0 .81.17s.2.1 0 0c-.61-.39-1.22-.77-1.82-1.18-2.5-1.74-4.93-3.57-7.37-5.4q-5.69-4.27-11.31-8.62c-4.8-3.69-8.82-6.79-13.75-10.62q-15.78-12.27-31.5-24.62-14.1-11.06-28.15-22.15l-15.28-12.09-.7-.55a2.58 2.58 0 00-3.53 0 2.52 2.52 0 000 3.54l10.91 8.63 26.27 20.69q15.9 12.51 31.86 24.95c9.31 7.25 18.63 14.5 28 21.64 3.09 2.36 6.19 4.71 9.34 7a35.29 35.29 0 004.66 3.2c1.91 1 3.43-.14 5-1.21 2.48-1.71 4.89-3.52 7.31-5.33 8.67-6.49 17.24-13.12 25.81-19.74q15.94-12.34 31.81-24.77 14.26-11.15 28.5-22.34l15.45-12.16.71-.56-4.27-1.77v135.4c0 1.82-.13 3.7 0 5.52a1.76 1.76 0 010 .23l2.5-2.5H68c-2.88 0-5.79-.12-8.67 0H59l2.5 2.5V146c0-5.72.19-11.48 0-17.2v-.23c-.02-3.26-5.02-3.26-5.02-.04z"
        transform="translate(-4.47 -1.63)"
      />
      <LightGreyLine d="M56.98 265.49l77.77-74 7.33 5.99-85.1 68.01zM273.86 265.49l-75.58-74-9.41 5.99 84.99 68.01z" />
      <GreyLine
        d="M56.69 128.53v123.72c0 5.72-.19 11.48 0 17.2v.23a2.53 2.53 0 002.5 2.5h213.05c2.89 0 5.78.06 8.67 0h.38a2.53 2.53 0 002.5-2.5V146c0-5.72.21-11.48 0-17.2v-.23c0-2.36-2.65-3-4.27-1.77q-5.38 4.26-10.79 8.5-12.94 10.19-25.91 20.34Q227 168 211.22 180.26l-7.8 6-3.34 2.59-3.55 2.74q-6.42 4.94-12.86 9.84c-4.75 3.6-9.47 7.29-14.47 10.55-.06 0-.19.07-.24.12a1.86 1.86 0 011.07-.28c1 0 .31 0 .81.17s.2.1 0 0c-.6-.39-1.22-.77-1.82-1.18-2.5-1.74-4.93-3.57-7.37-5.4q-5.69-4.27-11.31-8.62c-4.8-3.69-8.81-6.79-13.74-10.62q-15.79-12.27-31.51-24.62Q91 150.53 77 139.43l-15.35-12.12-.7-.55a2.58 2.58 0 00-3.53 0 2.52 2.52 0 000 3.54l10.92 8.63q13.11 10.35 26.26 20.69 15.91 12.51 31.87 24.95c9.31 7.25 18.63 14.5 28 21.64 3.09 2.36 6.2 4.71 9.34 7a36.66 36.66 0 004.66 3.2c1.92 1 3.43-.14 5-1.21 2.48-1.71 4.9-3.52 7.31-5.33 8.68-6.49 17.25-13.12 25.81-19.74q15.94-12.34 31.82-24.77 14.26-11.15 28.49-22.34 7.74-6.08 15.45-12.16l.72-.56-4.27-1.77v135.4c0 1.82-.14 3.7 0 5.52v.23l2.5-2.5H68.23c-2.88 0-5.79-.12-8.67 0h-.37l2.5 2.5V146c0-5.72.18-11.48 0-17.2a1.76 1.76 0 010-.23c0-3.26-5-3.26-5-.04z"
        transform="translate(-4.47 -1.63)"
      />
      <LightGreyLine d="M62.24 269.68s102.51-84.2 107.79-84.2 107.39 84.2 107.39 84.2z" transform="translate(-4.47 -1.63)" />
      <GreyLine
        d="M64 271.45q5.08-4.17 10.18-8.33 12.3-10 24.66-20 15-12.15 30.19-24.18 7.22-5.73 14.49-11.4c1.1-.86 2.2-1.72 3.31-2.57l.42-.33c.34-.26-.46.35.09-.06l.66-.58 1.73-1.33q3-2.28 5.94-4.52c3.15-2.38 6.31-4.73 9.55-7a81.64 81.64 0 013.29-2.19c.4-.25.81-.5 1.23-.73s1.48-.67.36-.22c.53-.21.58-.07-.09-.07.37 0-.91-.11-.4 0 .15 0 .61.25.06 0s0 0 .13.07l.52.28c.4.22.8.47 1.2.71 2.63 1.65 5.14 3.49 7.64 5.32 3.77 2.77 7.49 5.61 11.19 8.46l.74.57c.32.25-.47-.36.07.06l.33.25 1.71 1.34 3.38 2.64q3.53 2.74 7 5.52 15.19 12 30.23 24.2 13.41 10.85 26.75 21.76 7.2 5.9 14.39 11.8l.68.56 1.76-4.27H71c-2.8 0-5.6-.05-8.4 0h-.36c-3.22 0-3.23 5 0 5h206.42c2.8 0 5.6.07 8.4 0h.36c2 0 3.5-2.84 1.77-4.26q-5.13-4.25-10.29-8.46-12.4-10.2-24.9-20.3-15.16-12.3-30.42-24.46c-9-7.18-18.09-14.34-27.31-21.29-3.1-2.34-6.21-4.66-9.41-6.87-1.75-1.21-3.66-2.73-5.73-3.39-1.84-.58-3.51.48-5 1.42-2.59 1.6-5.07 3.41-7.53 5.21-8.58 6.25-16.93 12.8-25.27 19.36q-15.28 12-30.39 24.22-13.59 10.94-27.11 22-7.35 6-14.69 12l-.68.56a2.51 2.51 0 000 3.53 2.56 2.56 0 003.54-.03z"
        transform="translate(-4.47 -1.63)"
      />
    </g>
    <ShadowCircle cx="165.36" cy="335.78" rx="125.85" ry="8.69" />
    <BlinkedFigure duration={4}>
      <LightGreyLine
        d="M343.59 63.42v1c0 1.08.1-.44 0 .19a13.68 13.68 0 01-.45 1.81c-.07.21-.15.42-.22.62.28-.77-.11.22-.16.32a13.74 13.74 0 01-1 1.68c.38-.55.08-.09 0 0s-.43.49-.66.73a12.75 12.75 0 01-1.22 1.1c.59-.46-.65.39-.86.51s-.59.31-.88.46c-.88.46.62-.15-.33.14-.54.16-1.07.34-1.62.46-.11 0-1.2.16-.35.08q-.44 0-.87.06a14.45 14.45 0 01-1.74-.05c-.65 0 .85.21-.2 0-.38-.09-.77-.16-1.15-.27l-.79-.25c-1-.3.58.34-.34-.14-.48-.25-1-.5-1.43-.8l-.55-.37c.67.47-.18-.15-.26-.23a15.09 15.09 0 01-1.37-1.4c-.68-.77.24.37-.11-.15-.15-.23-.31-.45-.45-.69a12.67 12.67 0 01-.77-1.46c.34.77-.07-.25-.1-.34s-.13-.43-.19-.65-.15-.66-.22-1c-.2-.84 0 .44-.05-.36a17.58 17.58 0 010-1.92c0-.2.12-.95 0-.21.05-.33.12-.67.2-1a12.74 12.74 0 01.5-1.59c-.24.63-.06.11 0 0 .21-.39.4-.79.63-1.16.11-.19.24-.37.36-.55.52-.88-.48.47.2-.29a14 14 0 011.13-1.18 8.93 8.93 0 01.74-.64c-.14.1-.55.35 0 0s1.13-.68 1.71-1c.93-.49-.66.18.34-.14l.63-.2c.32-.1.65-.18 1-.26 0 0 1.07-.17.51-.1s.57 0 .54 0h1.75c.45.05.45 0 0 0l.51.09a14.74 14.74 0 011.94.55c.59.21-.75-.4.17.08.34.17.69.34 1 .54l.69.44q.53.38 0 0c.13.1.26.2.38.31a18.23 18.23 0 011.39 1.39c.13.14.53.72.12.14q.33.47.63 1c.14.24.27.48.4.72s.19.4.28.6c-.14-.34-.16-.38-.06-.13a16.46 16.46 0 01.55 1.94c0 .19.12.93 0 .19 0 .46.06.92.07 1.39a3.75 3.75 0 107.5 0 17 17 0 00-11.23-15.8 16.76 16.76 0 00-18.23 4.93 16.72 16.72 0 0016.15 27.24c7.75-1.45 13.24-8.65 13.34-16.38a3.75 3.75 0 00-7.5 0zM4.47 81.9a11.38 11.38 0 009.61-5.49s.31 7.63 5 9.46c0 0-6.41.46-9.15 5 .07.03-.12-7.14-5.46-8.97z"
        transform="translate(-4.47 -1.63)"
      />
    </BlinkedFigure>
    <CheckedMark
      d="M158.1 136.6l7.12 7.73a2.51 2.51 0 003.53 0l15.49-15.84 2.21-2.26c2.25-2.3-1.29-5.84-3.54-3.54l-15.49 15.85-2.2 2.26h3.53l-7.12-7.73c-2.18-2.37-5.71 1.17-3.53 3.53z"
      transform="translate(-4.47 -1.63)"
    />
    <CheckedCircle
      d="M197.09 133.58a25.33 25.33 0 11-20.51-24.88 25.56 25.56 0 0120.51 24.88c0 3.22 5 3.23 5 0a30.41 30.41 0 10-.42 5 33.24 33.24 0 00.42-5c.02-3.22-4.98-3.22-5 0z"
      transform="translate(-4.47 -1.63)"
    />
  </svg>
)

EmailSended.displayName = 'EmailSended'
